import React, { useState } from 'react'
import './styles.css' // Importe o arquivo CSS
import logoChannels from './images/new-channels.png' // Importe a imagem aqui

function App() {
  const [youtubeID, setYoutubeID] = useState('c8j52m2voy0') // Defina o estado para o ID do vídeo

  const containerBackground = {
    background: 'linear-gradient(180deg, #52135C, #000000)',
    color: '#fff', // Definindo a cor do texto do h1 como branco
    minHeight: '100vh', // Para garantir que o gradiente cubra toda a tela
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Poppins, sans-serif' // Aplicando a fonte "Poppins" ao texto
  }

  // Estilos para o vídeo ocupar toda a largura e altura disponível
  const videoContainerStyles = {
    width: '88%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  }
  const iframeStyles = {
    border: 'none',
    width: '100%',
    height: '100%'
  }
  const headingStyles = {
    margin: '20px 2px', // Adiciona margem na esquerda e direita (20px)
    fontSize: '20px', // Ajuste o tamanho da fonte conforme desejado
    fontWeight: 'bold', // Define a fonte como negrito, se desejar
    textAlign: 'center' // Centraliza o texto
  }
  // Estilos para o parágrafo (p) com margem na esquerda e direita
  const paragraphStyles = {
    margin: '2px 20px', // Adiciona margem na esquerda e direita (20px)
    fontSize: '10px',
    fontWeight: 'bold', // Define a fonte como negrito, se desejar
    textAlign: 'center' // Centraliza o texto
  }
  const imageStyles = {
    width: '330px', // Defina a largura desejada (por exemplo, 200 pixels)
    height: 'auto' // Mantenha a proporção original da imagem
  }
  function changeVideo(videoId) {
    setYoutubeID(videoId) // Atualiza o estado com o novo ID do vídeo
  }
  return (
    <div className="App" style={containerBackground}>
      <h1 style={headingStyles}>GRADE DE CANAIS - WOMP PLAY</h1>

      <div>
        <button
          className="video-button"
          onClick={() => changeVideo('c8j52m2voy0')}
        >
          Português
        </button>
        <button
          className="video-button"
          onClick={() => changeVideo('XeoJ5UA9_6M')}
        >
          English
        </button>
        <button
          className="video-button"
          onClick={() => changeVideo('c8j52m2voy0')}
        >
          日本語
        </button>
      </div>
      <div style={videoContainerStyles}>
        <iframe
          id="youtubePlayer" // Adicionando um ID ao elemento iframe
          className="video"
          title="Youtube player"
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={`https://youtube.com/embed/${youtubeID}?autoplay=1&controls=0&showinfo=0`}
          style={iframeStyles}
        ></iframe>
      </div>
      <div>
        {/* Texto que aparecerá abaixo do cabeçalho */}
        <p style={paragraphStyles}>
          Assista ao vídeo acima e entenda como o nosso sistema funciona.
        </p>
      </div>

      <h1 style={headingStyles}>EXPLORE NOSSA GRADE DE PROGRAMAÇÃO</h1>

      <div>
        <img src={logoChannels} alt="Logo" style={imageStyles} />
      </div>
    </div>
  )
}

export default App
